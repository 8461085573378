body {
  margin: 0;
  font-family: "Assistant", -apple-system, BlinkMacSystemFont, "Segoe UI",
    "Roboto", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans",
    "Helvetica Neue", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
.filepond--root {
  font-family: "Assistant", -apple-system, BlinkMacSystemFont, "Segoe UI",
    "Roboto", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans",
    "Helvetica Neue", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.filepond--item {
  width: calc(50% - 0.5em);
}

@media (min-width: 30em) {
  .filepond--item {
    width: calc(50% - 0.5em);
  }
}

@media (min-width: 50em) {
  .filepond--item {
    width: calc(50% - 0.5em);
  }
}

.image-gallery-content .image-gallery-slide .image-gallery-image {
  height: 50vh;
  object-fit: cover;
}

.image-gallery-content.fullscreen {
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  .image-gallery-slide .image-gallery-image {
    width: 100vw;
    height: calc(100vh - 80px);
    object-fit: contain;
  }
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

body {
  direction: rtl;
  *:focus {
    outline: none;
  }
}
